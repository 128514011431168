import { DocumentType, ProduitType, StockType } from '@innedit/innedit-type';
import { navigate, PageProps } from 'gatsby';
import { GenericData, ProduitData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Item from '~/components/List/Item/Stock';
import CMSView from '~/components/View';
import List from '~/containers/Espace/List';
import IconChevronLeft from '~/icons/ChevronLeft';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageProduitsStock: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  location,
  user,
  params: { espaceId, produitId },
}) => {
  const [product, setProduct] = useState<DocumentType<ProduitType>>();

  useEffect(() => {
    let isMounted = true;
    if (produitId) {
      const produitData = new ProduitData({
        espaceId,
      });

      produitData
        .findById(produitId)
        .then(doc => {
          if (isMounted) {
            setProduct(doc);
          }

          return isMounted;
        })
        .catch(error => toast.error(error.message));
    }

    return () => {
      isMounted = false;
    };
  }, [produitId]);

  const model = new GenericData<StockType>({
    collectionName: 'stocks',
    parentCollectionName: 'produits',
    parentId: produitId,
  });

  const handleCloseOnClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    return navigate(-1);
  };

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          itemList={Item}
          itemPathnamePrefix={`/espaces/${espaceId}/produits/stocks/`}
          menu={{
            left: [
              {
                icon: IconChevronLeft,
                label: 'Revenir au produit',
                onClick: handleCloseOnClick,
              },
            ],
          }}
          model={model}
          nbParPage={20}
          pathname={location.pathname}
          removeAddButton
          search={location.search}
          title={`${product?.label}`}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageProduitsStock);
